<template>
  <div
      v-loading.fullscreen.lock="loading"
      class="login-page-box flex-def flex-zCenter flex-cCenter"
      :style="fullBgImg"
  >
    <div v-if="!loading" class="y-login-box flex-def">
      <div v-if="page_set.banner" class="left">
        <img :src="page_set.banner" class="flex-def" style="height: 100%;width: 28rem" alt="">
      </div>
      <div class="right flex-def flex-zTopBottom flex-cCenter flex-zCenter">
        <div style="margin-bottom: 3rem;font-size: 2rem;font-weight: 600;text-align: center">{{ page_set.title }}</div>
        <el-form style="width: 80%" size="large" :model="auth_form" label-position="top" :rules="auth_form_rules">
          <el-form-item label="登录账号" prop="username">
            <el-input v-model="auth_form.username" placeholder="请输入登录账号"></el-input>
          </el-form-item>
          <el-form-item style="padding-top: 1rem" label="登录密码" prop="password">
            <el-input show-password type="password" v-model="auth_form.password" placeholder="请输入登录密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="login" style="width: 100%;margin-top: 4rem" size="large" type="primary">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom footer">
      <div>{{ page_set.copyright }}</div>
      <div v-if="page_set.icp">
        <span>备案号：</span>
        <a href="http://beian.miit.gov.cn/" target="_blank">{{ page_set.icp }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import {setRemember} from "@/common/util";
export default {
  data(){
    return{
      loading:true,
      page_set:{
        title:"",
        bg:"",
        icp:"",
        copyright:"",
      },
      auth_form:{
        username:"",
        password:"",
      },
      auth_form_rules:{
        username:[{required: true, message: '请输入登录账号', trigger: 'blur'}],
        password:[{required: true, message: '请输入登陆密码', trigger: 'blur'}]
      }
    }
  },
  methods:{
    login(){
      this.$u.login(this.auth_form).then(res=>{
        setRemember({username:this.auth_form.username,password:this.auth_form.password})
        this.$router.push(res.home_page);
      });
    },
  },
  mounted() {
    this.$u.api.account.loginPage().then(res=>{
      this.page_set = res;
      this.loading = false;
    })
  },
  computed:{
    fullBgImg(){
      return this.page_set.bg ? `background-image:url(${this.page_set.bg})` : '';
    },
    svg(){
      return `<path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`
    }
  }
}
</script>
<style>
.el-form--label-top .el-form-item__label {
  font-weight: 600;
  padding-bottom: 0 !important;
}
</style>

<style scoped>
.login-page-box {
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.y-login-box {
  background-color: #FFFFFF;
  box-shadow: 1px 1px 5px 1px #ededed;
  border-radius: .5rem;
  min-height: 37.5rem;
  min-width: 20rem;
  overflow: hidden;
}

.left {
  height: 37.5rem;
}

.right {
  height: 37.5rem;
  width: 30rem;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: #888888;
  left: 0;
  right: 0;
  padding: 1rem;
}

a, a:focus {
  color: inherit;
  text-decoration: none;
}
</style>